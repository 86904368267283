
@import "../../../assets/style/variables.scss";
.event-stat {
  margin: 6.4rem 0 0;
  &__item {
    font-size: 3.2rem;
    color: $blue-03;
    display: flex;
    justify-content: flex-start;
    line-height: 1;
    &__icon {
      margin-top: -3px;
      img {
        width: 3.2rem;
      }
    }
    &__text {
      color: $blue-0;
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }
}
