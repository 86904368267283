// Variables Bootstrap 4
$green:                       #349C6A;
$primary:                     #1C75BC;
$secondary:                   #ED1B2F;
$grey:                        #eee;
$grey-light:                  #D5DCEB;
$grey-middle:                 #7A7A7C;
$grey-dark:                   #555;
$grey-middle-dark:            #A4A4A7;
$black:                       #000000;
$white:                       #ffffff;
$bg:                          #f8f8f8;
$sidebar-bg:                  #344883;
$sidebar-section:             #2E386B;
$sidebar-section-border:      #8A98BD;
$pink:      #D76285;
$bg-pink:      #FACEDB;
$bg-blue:      #D7E8F8;

$black-02:                    #7A7A7C;
$black-03:                    #002E5E;
$blue-03:                    #002E5E;

$green-01:                    #349C6A;
$green-02:                    #27AE60;

$red:                         $secondary;
$red-hover:                   #C61929;
$red-01:                      #FF0000;

$grey-02:                     #7a7a7a;
$grey-03:                     #4f4f4f;
$grey-04:                     #c4c4c4;
$grey-05:                     #828282;
$grey-06:                     #f2f2f2;
$grey-07:                     #E0E0E0;
$grey-bg:                     #FBFBFB;
$disabled:                    #BDBDBD;

$blue-0:                      #333;
$blue-01:                     #4151A2;
$blue-02:                     $primary;
$blue-03:                     #002E5E;
$blue-04:                     #BDBDBD;
$blue-05:                     #F2F5FE;

$sidebar-bg:                  $blue-0;

$text:                        $blue-0;

$box-shadow-default:          0 1.6rem 2.4rem rgba($black, 0.08), 0 0.2rem 0.6rem rgba($black, 0.04), 0 0 0.1rem rgba($black, 0.04);
$box-shadow-hover:            0 1.6rem 2.4rem rgba($black, 0.2), 0 0.2rem 0.6rem rgba($black, 0.04), 0 0 0.1rem rgba($black, 0.04);


$body-bg:                     $bg;
$font-family-base:            Circe, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-sans-serif:      Circe, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-circle-extra:    "Circle Extra", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$body-color:                  $text;
$shadow-color:                #e0e4e7;
$shadow-color-dark:           #bdc0c2;
$border-radius:               6px;
$border-radius-big:           1.6rem;

// animation duration
$animation-duration:          0.3s;

// Breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 769px,
    lg: 992px,
    xl: 1200px
) !default;

// Spacers
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
        (
            0: 0,
            1: ($spacer),
            2: ($spacer * 2),
            3: ($spacer * 3),
            4: ($spacer * 4),
            5: ($spacer * 5),
            6: ($spacer * 6),
            7: ($spacer * 7),
            8: ($spacer * 8),
            9: ($spacer * 9),
            10: ($spacer * 10),
            11: ($spacer * 11),
            12: ($spacer * 12),
        ),
        $spacers
);

// Cards
$card-spacer-y:                     0;
$card-spacer-x:                     0;
$card-border-width:                 0;
$card-border-radius:                $border-radius-big;
$card-border-color:                 transparent;
$card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
$card-cap-bg:                       rgba($black, .03) !default;
$card-cap-color:                    null;
$card-height:                       null;
$card-color:                        null;
$card-bg:                           $white;
$card-shadow:                       0px 0px 20px rgba(195, 212, 219, 0.15);
$card-padding:                      3.5rem;

// Badges
$badge-font-size:                   1.2rem;
$badge-padding-y:                   .4rem;
$badge-padding-x:                   1rem;
$badge-border-radius:               0.2rem;
$badge-font-weight:                 600;

// Modal
// Padding applied to the modal body
$modal-inner-padding:               3rem !default;
$modal-content-border-width:        0;

$modal-content-border-radius:       0;

$modal-header-padding-y:            4rem;
$modal-header-padding-x:            4rem;

$modal-xl:                          1140px !default;
$modal-lg:                          690px;

// modal
$modal-backdrop-opacity:            0.6;
$modal-backdrop-bg:                 $black;
$modal-header-padding:              4rem;
$modal-header-border-width:         0;
$modal-content-border-radius:       0;
$modal-inner-padding:               0 4rem 4rem;

// dropdown
$dropdown-padding-y:                0;
$dropdown-spacer:                   0;
$dropdown-font-size:                1.6rem;
$dropdown-color:                    $black;
$dropdown-bg:                       $white;
$dropdown-border-color:             transparent;
$dropdown-border-radius:            0.2rem;
$dropdown-box-shadow:               0px 3px 30px rgba(46, 61, 121, 0.28);
$dropdown-item-padding-y:           1.6rem;
$dropdown-item-padding-x:           2.4rem;
$dropdown-link-color:               $black;
$dropdown-link-hover-color:         $black;
$dropdown-link-hover-bg:            $blue-05;
$dropdown-link-active-color:        $blue-02;
$dropdown-link-active-bg:           $blue-05;

// breadcrumb
$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-margin-bottom:          3rem;
$breadcrumb-font-size:              1.4rem;
$breadcrumb-bg:                     transparent;
$breadcrumb-border-radius:          0;
$breadcrumb-active-color:           $grey-middle-dark;
$breadcrumb-divider-color:          $grey-middle-dark;

// button
$btn-padding-y:               0.8rem;
$btn-padding-x:               3rem;
$btn-font-family:             $font-family-sans-serif;
$btn-font-size:               1.6rem;
$btn-line-height:             1.5;
$btn-border-radius:           2.4rem;

// input
$input-btn-padding-y:         1.2rem !default;
$input-btn-padding-x:         1.3rem !default;
$input-padding-y:                       1.2rem !default;
$input-padding-x:                       1.4rem !default;
$input-font-size:                       1.8rem !default;
$input-line-height:                     1.3 !default;

$input-bg:                              $grey-06 !default;
$input-disabled-bg:                     $grey-06 !default;

$input-color:                           $text !default;
$input-border-width:                    0 !default;
$input-box-shadow:                      inset 0 1px 1px rgba($black, .075) !default;

$input-border-radius:                   0 !default;
$input-border-radius-lg:                0 !default;
$input-border-radius-sm:                0 !default;

$input-focus-bg:                        $grey-07 !default;
$input-focus-border-color:              transparent !default;
$input-focus-color:                     $text !default;
$input-focus-width:                     0 !default;
$input-focus-box-shadow:                none !default;

$input-placeholder-color:               $disabled !default;
$input-plaintext-color:                 $text !default;

$input-height-border:                   0 !default;

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y / 2) !default;

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;

$input-transition:                      background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$input-btn-border-width:      0 !default;

// Navs
$nav-link-padding-y:                2rem;
$nav-link-padding-x:                1.2rem;
$nav-tabs-border-color:             #eee;
$nav-tabs-border-width:             0.4rem !default;
$nav-tabs-border-radius:            0;
$nav-tabs-link-hover-border-color:  transparent transparent $nav-tabs-border-color;
$nav-tabs-link-active-color:        #000;
$nav-tabs-link-active-bg:           transparent;
$nav-tabs-link-active-border-color: transparent transparent $primary !default;

