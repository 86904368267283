
@import "@/../src/assets/style/variables.scss";
.company-stat-list {
  display: flex;
  flex-flow: wrap;
  padding: 3.6rem 0;
  &__title {
    width: 30%;
    flex: 0 0 30%;
    padding: 0 2.4rem;
    line-height: 2.4rem;
    font-size: 1.8rem;
  }
  &__mark, &__text {
    flex-grow: 1;
    border-left: 2px solid #BDBDBD;
    padding: 0 2.4rem;
    color: $grey-02;
    a {
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.4rem; /* 133.333% */
      color: $blue-0;
      text-decoration: underline;
    }
    .d-flex {
      height: 100%;
    }
  }
  &__mark {
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.6rem;
    sub {
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2.4rem;
    }
  }
}
